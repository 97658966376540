import React from 'react';
import { connect } from 'react-redux';
import {
  toggleDarkMode,
  toggleFullWidth,
  toggleDebugMode,
} from '../appActions';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import iziToast from 'izitoast';

const mapDispatch = { toggleDarkMode, toggleFullWidth, toggleDebugMode };

function mapState(state) {
  return {
    username: state.auth.account.name,
    userID: state.auth.account.uid,
    userPerms: state.auth.account.perms,
    apiurl: state.auth.appConfig.apiurl,
    baseurl: state.auth.appConfig.baseurl,

  };
}

export class Navbar extends React.Component {
  constructor(props, history) {
    super(props);
    this.toggleDarkMode = this.toggleDarkMode.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleSubMenu = this.toggleSubMenu.bind(this);
    this.state = {
      menu: false,
      submenu: false,
      loading: false,
    };
  }

  toggleDarkMode() {
    this.props.toggleDarkMode();
  }
  toggleMenu() {
    this.setState(Object.assign({}, { menu: !this.state.menu }));
  }
  closeMenu() {
    if (this.state.menu) {
      this.setState(Object.assign({}, { menu: !this.state.menu }));
    }
  }
  toggleSubMenu() {
    this.setState(Object.assign({}, { submenu: !this.state.submenu }));
  }

  doLogout = () => {
    if (this.state.loading !== false) {
      return;
    }

    let me = this;
    iziToast.info({
      title: 'Attendere prego..',
      message: '',
    });

    axios
      .get(this.props.apiurl + '/logout', { withCredentials: true })
      .then((whoRes) => {
        me.setState({ loading: false });
        if (document.querySelector('.iziToast')) {
          iziToast.hide({}, document.querySelector('.iziToast'));
        }
        iziToast.success({
          title: 'Logout effettuato.',
          overlay: true,
          position: 'center',
        });
        me.props.setAuth(whoRes.data.account); // should receive default anonymous account
        me.props.history.push('/login');
      })
      .catch((err) => {
        if (document.querySelector('.iziToast')) {
          iziToast.hide({}, document.querySelector('.iziToast'));
        }
        iziToast.error({
          title: 'Errore',
          message: 'Si è verificato un errore tecnico..',
        });
      });
  };

  render() {
    
    let logoURI = `${this.props.baseurl}logo.png?r=${Math.random()}`;
    if (true === this.props.darkMode) {
      logoURI = `${this.props.baseurl}logo-invert.png`;
    }
    let menuOpen = 'navbar-menu';
    if (true === this.state.menu) {
      menuOpen = 'navbar-menu is-active';
    }
    let links = [];
    if (this.props.links && this.props.links.length) {
      this.props.links.forEach((item, i) => {
        let lk = `navbar-link-${i}`;
        if (
          -1 !== this.props.userPerms.indexOf(`${item.module}:read`) ||
          -1 !== this.props.userPerms.indexOf(`${item.module}:update`)
        ) {
          links.push(
            <Link key={lk} to={item.path} className="navbar-item" onClick={this.toggleMenu}>
              {item.nav.label.toUpperCase()}
            </Link>
          );
        }
      });
    }

    return (
      <nav className="navbar is-fixed-top is-white" id="mainNavigation">
        <div className="container">

          <div className="navbar-brand">
            <Link to="/" className="navbar-item" onClick={this.closeMenu}>
              {/*<span className="icon is-large mr-2"></span>
              MHS */}
              <img src={logoURI} width="112" height="28"/>
            </Link>
            <div className="navbar-burger burger" onClick={this.toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className={menuOpen}>
            <div className="navbar-start">{links}</div>
            <div className="navbar-end">
              { -1 !== this.props.userPerms.indexOf(`settings:read`) && 
                <Link to="/admin" className="navbar-item">
                  <span className="icon is-large">
                    <span className="is-large mhs-cog"></span>
                  </span>
                </Link>
              }
              <Link to={`/user/${this.props.userID}`} className="navbar-item">
                {this.props.username}
              </Link>
              <a onClick={this.doLogout} className="navbar-item">
                <span className="icon">
                  <span className="is-large mhs-logout"></span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default connect(mapState, mapDispatch)(withRouter(Navbar));
