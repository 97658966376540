import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
//import { checkAuth } from '../appActions'


const mapDispatch = { };

function mapState(state) {
  return { };
}

export class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    return ( <div>
      <h1>404 NotFoundPage</h1>
    </div> );
  }
}

export default connect( mapState, mapDispatch )( NotFoundPage )
