import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import iziToast from 'izitoast';
import { withRouter } from 'react-router-dom';

const mapDispatch = {};

function mapState(state) {
  return {};
}

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      passwd: '',
      rememberme: false,
      loading: false,
    };
    this.doLogin = this.doLogin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  doLogin = () => {
    if (this.state.loading !== false) {
      return;
    }

    let me = this;
    iziToast.info({
      title: 'Attendere prego..',
      message: '',
    });

    axios
      .post(
        this.props.config.apiurl + '/login/',
        {
          user: this.state.email,
          pwd: this.state.passwd,
          rem: this.state.rememberme,
        },
        { withCredentials: true }
      )
      .then((whoRes) => {
        me.setState({ loading: false });
        if (document.querySelector('.iziToast')) {
          iziToast.hide({}, document.querySelector('.iziToast'));
        }
        this.props.setAuth(whoRes.data.account);
        me.props.bootStrap();

        if (true === whoRes.data.account.logged) {
          iziToast.success({
            title: 'Bentornato ' + whoRes.data.account.name,
            overlay: true,
            position: 'center',
            timeout: 1000,
          });
          this.props.history.push('/dashboard');
        } else {
          iziToast.warning({
            title: 'Attenzione',
            message: 'Controllare i dati immessi.',
            overlay: true,
            position: 'center',
          });
        }
      })
      .catch((err) => {
        /*
         *** gotinsane dlog ***
         *** gotinsane dlog ***
         */
        console.info('##dlog\t ERR?');
        console.info(err);
        if (document.querySelector('.iziToast')) {
          iziToast.hide({}, document.querySelector('.iziToast'));
        }
        iziToast.error({
          title: 'Errore',
          message: 'Si è verificato un errore tecnico',
        });
      });
  };

  handleInput = (e) => {
    let tmpState = Object.assign({}, this.state);
    if (e.target.name === 'rememberme') {
      if (e.target.checked) {
        tmpState.rememberme = true;
      } else {
        tmpState.rememberme = false;
      }
    } else {
      tmpState[e.target.name] = e.target.value;
    }
    this.setState(tmpState);
  };

  render() {
    let heroStyle = {
      backgroundImage: `url("${this.props.config.baseurl}images/login_background.jpg")`,
    };
    let cthLogoUri = this.props.config.baseurl + 'images/cth_logo.png';
    return (
      <section
        className="hero is-primary is-fullheight login-hero"
        style={heroStyle}
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6-tablet is-5-desktop is-4-widescreen">
                <div className="card">
                  <form className="card-content" onSubmit={this.handleSubmit}>
                    <div className="field">
                      <label htmlFor="email" className="label">
                        Email
                      </label>
                      <div className="control has-icons-left">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="e.g. bobsmith@gmail.com"
                          className="input"
                          required
                          onChange={this.handleInput}
                        />
                        <span className="icon is-small is-left">
                          <i className="mhs-user"></i>
                        </span>
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="passwd" className="label">
                        Password
                      </label>
                      <div className="control has-icons-left">
                        <input
                          type="password"
                          id="passwd"
                          name="passwd"
                          placeholder="*******"
                          className="input"
                          required
                          onChange={this.handleInput}
                        />
                        <span className="icon is-small is-left">
                          <i className="mhs-lock"></i>
                        </span>
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="rememberme" className="checkbox">
                        <input
                          type="checkbox"
                          id="rememberme"
                          name="rememberme"
                          onChange={this.handleInput}
                        />
                        &nbsp;
                        <span className="is-size-6">Ricorda Sessione</span>
                      </label>
                    </div>
                    <div className="field">
                      <button
                        className="button is-success is-fullwidth"
                        onClick={this.doLogin}
                      >
                        Login
                      </button>
                    </div>
                    <div className="level">
                      <div className="level-item is-size-7">
                        <figure
                          className="image is-24x24 is-logo"
                          style={{ backgroundImage: `url("${cthLogoUri}")` }}
                        ></figure>
                        You gotta love &nbsp;
                        <a
                          className="has-text-weight-bold"
                          href="http://www.certhidea.it"
                          target="_blank"
                        >
                          Certhidea
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default connect(mapState, mapDispatch)(withRouter(Login));
