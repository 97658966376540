import axios from 'axios';

export default class Api {
  constructor() {
    this.config = false;
    this.setConfig = this.setConfig.bind(this);
  }
  setConfig(config) {
    this.config = config;
  }
  checkAuth() {
    if (!this.config) {
      return new Promise((resolve, reject) => {
        reject(false);
      });
    }
    return axios
      .get(`${this.config.apiurl}/auth/whoami`, {
        withCredentials: true,
        sameSite: 'none',
      })
      .then((whoRes) => {
        return whoRes.data;
      })
      .catch((err) => {
        /*
          ######
          ######
        */
        console.log('#############\t\t\tcheckAuth.catch.err');
        console.log(err);
        return false;
      });
  }
  loadTaxonomy() {
    if (!this.config) {
      return new Promise((resolve, reject) => {
        reject(false);
      });
    }
    return axios
      .get(`${this.config.apiurl}/taxonomy`, {
        withCredentials: true,
        sameSite: 'none',
      })
      .then((taxonomyRes) => {
        if (taxonomyRes.data) {
          return taxonomyRes.data;
        } else {
          return {};
        }
      })
      .catch((err) => {
        /*
          ######
          ######
        */
        console.log('#############\t\tloadTaxonomy.catch.err');
        console.log(err);
        return false;
      });
  }
  loadBranding() {
    if (!this.config) {
      return new Promise((resolve, reject) => {
        reject(false);
      });
    }
    return axios
      .get(`${this.config.apiurl}/branding`, {
        withCredentials: true,
        sameSite: 'none',
      })
      .then((brandingRes) => {
        if (brandingRes.data) {
          return brandingRes.data;
        } else {
          return {};
        }
      })
      .catch((err) => {
        /*
          ######
          ######
        */
        console.log('#############\t\tloadbranding.catch.err');
        console.log(err);
        return false;
      });
  }
  loadRoles() {
    if (!this.config) {
      return new Promise((_resolve, reject) => {
        reject(false);
      });
    }
    return axios
      .get(`${this.config.apiurl}/roles`, {
        withCredentials: true,
        sameSite: 'none',
      })
      .then((rolesRes) => {
        if (rolesRes.data) {
          return rolesRes.data;
        } else {
          return {};
        }
      })
      .catch((err) => {
        /*
          ######
          ######
        */
        console.log('#############\t\tloadroles.catch.err');
        console.log(err);
        return false;
      });
  }
  loadTaxonomyAttachments(id) {
    if (!this.config) {
      return new Promise((resolve, reject) => {
        reject(false);
      });
    }
    return axios
      .get(`${this.config.apiurl}/taxonomy/${id}/attachments`, {
        withCredentials: true,
        sameSite: 'none',
      })
      .then((taxonomyRes) => {
        if (taxonomyRes.data) {
          return taxonomyRes.data;
        } else {
          return {};
        }
      })
      .catch((err) => {
        /*
          ######
          ######
        */
        console.log('#############\t\tloadTaxonomy.catch.err');
        console.log(err);
        return false;
      });
  }
  setTaxonomyAttachments() {
    if (!this.config) {
      return new Promise((resolve, reject) => {
        reject(false);
      });
    }
    return axios
      .get(`${this.config.apiurl}/taxonomy`, {
        withCredentials: true,
        sameSite: 'none',
      })
      .then((taxonomyRes) => {
        if (taxonomyRes.data) {
          return taxonomyRes.data;
        } else {
          return {};
        }
      })
      .catch((err) => {
        /*
          ######
          ######
        */
        console.log('#############\t\tloadTaxonomy.catch.err');
        console.log(err);
        return false;
      });
  }
}
