import { createAction } from '@reduxjs/toolkit';
import {
  ACTION_SET_AUTH,
  ACTION_AUTH_REQ_START,
  REQ_STATUS_IDLE,
  REQ_LAST_STATUS_ERROR,
  AUTH_CHECK_WHOAMI,
  ACTION_AUTH_ADD_ROUTES,
  ACTION_AUTH_REMOVE_ROUTES,
  ACTION_AUTH_SET_CONFIG,
  ACTION_AUTH_TOGGLE_DARKMODE,
  ACTION_AUTH_TOGGLE_DEBUGMODE,
  ACTION_AUTH_TOGGLE_FULLWIDTH,
  ACTION_SET_TAXONOMY,
  ACTION_SET_TAXONOMY_ATTACHMENTS,
  ACTION_SET_BRANDING,
  ACTION_SET_ROLES,
  ACTION_AUTH_ADD_QR_CODE,
  ACTION_AUTH_REMOVE_QR_CODE,
  SET_BASE_URL,
} from '../utilities/Types';

import AppApi from './api';

export const setAuth = createAction(ACTION_SET_AUTH);
export const setTaxonomy = createAction(ACTION_SET_TAXONOMY);
export const setBranding = createAction(ACTION_SET_BRANDING);
export const setRoles = createAction(ACTION_SET_ROLES);
export const setTaxonomyAttachments = createAction(
  ACTION_SET_TAXONOMY_ATTACHMENTS
);
export const reqStart = createAction(ACTION_AUTH_REQ_START);
export const reqIdle = createAction(REQ_STATUS_IDLE);
export const reqError = createAction(REQ_LAST_STATUS_ERROR);
export const checkWhoami = createAction(AUTH_CHECK_WHOAMI);
export const addRoutes = createAction(ACTION_AUTH_ADD_ROUTES);
export const removeModuleRoutes = createAction(ACTION_AUTH_REMOVE_ROUTES);
export const setConfig = createAction(ACTION_AUTH_SET_CONFIG);
export const setDarkMode = createAction(ACTION_AUTH_TOGGLE_DARKMODE);
export const addQrCode = createAction(ACTION_AUTH_ADD_QR_CODE);
export const removeQrCode = createAction(ACTION_AUTH_REMOVE_QR_CODE);
export const setDebugMode = createAction(ACTION_AUTH_TOGGLE_DEBUGMODE);
export const setFullWidth = createAction(ACTION_AUTH_TOGGLE_FULLWIDTH);
export const setBaseURL = createAction(SET_BASE_URL);
export const setWsURL = createAction('SET_WS_URL');

const Api = new AppApi();

export function initApp(config) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(setConfig(config));
      Api.setConfig(config);
      resolve(true);
    });
  };
}

export const toggleDarkMode = () => (dispatch) => {
  dispatch(setDarkMode());
  return new Promise((resolve, reject) => {
    resolve(true);
  });
};
export const toggleDebugMode = () => (dispatch) => {
  dispatch(setDebugMode());
  return new Promise((resolve, reject) => {
    resolve(true);
  });
};
export const toggleFullWidth = () => (dispatch) => {
  dispatch(setFullWidth());
  return new Promise((resolve, reject) => {
    resolve(true);
  });
};

export const loadTaxonomy = (apiurl) => (dispatch) => {
  dispatch(reqStart());
  return new Promise((resolve, reject) => {
    Api.loadTaxonomy(apiurl)
      .then((taxonomyRes) => {
        dispatch(setTaxonomy(taxonomyRes.taxonomy));
      })
      .catch((e) => {
        /*
         *** gotinsane dlog ***
         *** gotinsane dlog ***
         */
        console.info('##dlog\t loadTaxonomy.catch');
        console.info(e);
        dispatch(reqError(e));
        reject(e);
      })
      .finally(() => {
        dispatch(reqIdle());
        resolve(true);
      });
  });
};
export const loadBranding = (apiurl) => (dispatch) => {
  dispatch(reqStart());
  return new Promise((resolve, reject) => {
    Api.loadBranding(apiurl)
      .then((brandingRes) => {
        dispatch(setBranding(brandingRes.branding));
      })
      .catch((e) => {
        /*
         *** gotinsane dlog ***
         *** gotinsane dlog ***
         */
        console.info('##dlog\t loadbranding.catch');
        console.info(e);
        dispatch(reqError(e));
        reject(e);
      })
      .finally(() => {
        dispatch(reqIdle());
        resolve(true);
      });
  });
};

export const qrCodeSelect = (selection) => (dispatch) => {
  dispatch(addQrCode(selection));
};
export const qrCodeUnselect = (selection) => (dispatch) => {
  dispatch(removeQrCode(selection));
};
export const loadRoles = (apiurl) => (dispatch) => {
  dispatch(reqStart());
  return new Promise((resolve, reject) => {
    Api.loadRoles(apiurl)
      .then((rolesRes) => {
        dispatch(
          setRoles({ roles: rolesRes.roles, perms: rolesRes.permissions })
        );
      })
      .catch((e) => {
        /*
         *** gotinsane dlog ***
         *** gotinsane dlog ***
         */
        console.info('##dlog\t loadroles.catch');
        console.info(e);
        dispatch(reqError(e));
        reject(e);
      })
      .finally(() => {
        dispatch(reqIdle());
        resolve(true);
      });
  });
};

export const loadTaxonomyAttachments = (id) => (dispatch) => {
  dispatch(reqStart());
  return new Promise((resolve, reject) => {
    Api.loadTaxonomyAttachments(id)
      .then((taxonomyRes) => {
        dispatch(setTaxonomyAttachments({ id: id, attachments: taxonomyRes }));
      })
      .catch((e) => {
        /*
         *** gotinsane dlog ***
         *** gotinsane dlog ***
         */
        console.info('##dlog\t loadTaxonomy.catch');
        console.info(e);
        dispatch(reqError(e));
        reject(e);
      })
      .finally(() => {
        dispatch(reqIdle());
        resolve(true);
      });
  });
};

export const checkAuth = (apiurl) => (dispatch) => {
  dispatch(reqStart());
  return new Promise((resolve, reject) => {
    let account = false;
    Api.checkAuth(apiurl)
      .then((authRes) => {
        account = authRes.account;
        dispatch(setAuth(authRes.account));
        dispatch(setBaseURL(authRes.baseurl));
        dispatch(setWsURL(authRes.ws));
        // console.log("CHECK AUTH",authRes);
        return true;
      })
      .then(() => {
        dispatch(reqIdle());
      })
      .catch((e) => {
        /*
         *** gotinsane dlog ***
         *** gotinsane dlog ***
         */
        console.info('##dlog\t ApiCheckAuth.catch');
        console.info(e);
        dispatch(reqError(e));
        reject(e);
      })
      .finally(() => {
        resolve(account);
      });
  });
};
