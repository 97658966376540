import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux';


import rootReducer from './rootReducer'
import authReducer from './reducers/authReducer'
import { addRoutes, removeModuleRoutes } from './appActions'

const store = configureStore({
  reducer: rootReducer
})

store.asyncReducers = {
  auth: authReducer
};
store.asyncRoutes = {};

const createReducer = asyncReducers =>
  combineReducers({
    ...asyncReducers
  });

export const injectReducers = (store, name, asyncReducer) => {
  if ( store.asyncReducers[ name ] ) {
    return;
  }
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer( createReducer( store.asyncReducers ) );
};

export const removeReducer = (store, module, name ) => {
  if ( store.asyncReducers[ name ] ) {
    delete store.asyncReducers[ name ];
    store.replaceReducer( createReducer( store.asyncReducers ) );
  }
};

export const injectRoutes = (store, name, routes) => {
  if ( store.asyncRoutes[ name ] ) {
    return;
  }
  store.asyncRoutes[name] = routes;
  store.dispatch( addRoutes( { module: name, routes } ) );
};

export const removeRoutes = (store, name) => {
  if ( store.asyncRoutes[ name ] ) {
    delete store.asyncRoutes[ name ];
    store.dispatch( removeModuleRoutes( { module: name } ) );
  }
};

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export default store
