import { Component } from 'react';
import store, { injectReducers, injectRoutes, removeReducer, removeRoutes } from '../app/store'


export default class DynamicImport extends Component {
  state = {
    component: null,
    modulename: false,
    moduleReducers: []
  };

  componentDidMount() {
    const { load } = this.props;
    const modulename = this.props.module;

    load().then(module => {
      const { routes, component, reducers } = module;
      let moduleReducers = [];
      if ( reducers ) {
        reducers.forEach(reducer => {
          moduleReducers.push( reducer.name );
          injectReducers( store, reducer.name, reducer.fn );
        });
      }

      if ( routes ) {
        injectRoutes( store, modulename, routes );
      }


      this.setState({
        component: component ? component : module.default,
        modulename,
        moduleReducers
      });
    });
  }

  componentWillUnmount () {
    if ( this.state.moduleReducers && this.state.moduleReducers.length ) {
      this.state.moduleReducers.forEach((item, i) => {
        //removeReducer( store, this.props.module, item );
      });
    }
    //removeRoutes( store, this.props.module );
  }

  render() {
    return this.props.children( this.state.component );
  }
}
