import './App.css';

import React, { useState } from 'react';
import { connect } from 'react-redux';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';


import {
  reqStart,
  checkAuth,
  addRoutes,
  initApp,
  setAuth,
  loadTaxonomy,
  loadBranding,
  loadRoles,
} from './appActions';

const mapDispatch = {
  reqStart,
  checkAuth,
  addRoutes,
  initApp,
  setAuth,
  loadTaxonomy,
  loadBranding,
  loadRoles,
};

import NotFoundPage from './components/NotFoundPage';
import NavBar from './components/NavBar';
import Login from './components/Login';

import DynamicImport from '../utilities/dynamicImport';
import Loading from '../utilities/loading';

import config from '../config';

let _v = require('../../package.json').version;
let _n = require('../../package.json').name;
let passageCounter= 0;
const BASE_ROUTES = [];

const routeAnimation = {
  atEnter: {
    opacity: 0,
  },
  atLeave: {
    opacity: 0,
  },
  atActive: {
    opacity: 1,
  },
};

const mapStyles = (styles) => {
  return {
    opacity: styles.opacity,
  };
};

const App = ({
  appConfig,
  req,
  moduleRoutes,
  reqStart,
  initApp,
  checkAuth,
  addRoutes,
  darkMode,
  debugMode,
  fullWidth,
  userAccount,
  setAuth,
  loadTaxonomy,
  loadBranding,
  loadRoles,
}) => {
  const [state, _setState] = useState({ routes: BASE_ROUTES });

  //const pageLo = useLocation();
  const location = window.location;
  passageCounter +=1;

  if (false === appConfig) {
    initApp(config)
      .then(() => {
        return checkAuth(config.apiurl);
      })
      .then(() => {
        return loadTaxonomy(config.apiurl);
      })
      .then(() => {
        return loadBranding(config.apiurl);
      })
      .then(() => {
        return loadRoles(config.apiurl);
      })
      .catch((e) => {
        /*
         *** gotinsane dlog ***
         *** gotinsane dlog ***
         */
        console.info('##dlog\t checkAuth.then.catch');
        console.info(e);
      });
  }

  const bootStrap = () => {
    initApp(config)
      .then(() => {
        return loadTaxonomy(config.apiurl);
      })
      ///loadTaxonomy(config.apiurl)
      .then(() => {
        return loadBranding(config.apiurl);
      })
      .then(() => {
        return loadRoles(config.apiurl);
      })
      .catch((e) => {
        /*
         *** gotinsane dlog ***
         *** gotinsane dlog ***
         */
        console.info('##dlog\t bootStrap.then.catch');
        console.info(e);
      });
  };

  if (
    false === userAccount ||
    ('object' === typeof userAccount && userAccount.logged === false)
  ) {
    document.body.classList.remove('has-navbar-fixed-top');
    return (
      <Router>
        <Login config={config} setAuth={setAuth} bootStrap={bootStrap} />
      </Router>
    );
  } else {
    document.body.classList.add('has-navbar-fixed-top');
  }

  let routes = [];
  let NavLinks = [];

  if (state.routes && state.routes.length) {
    state.routes.forEach((item, i) => {
      routes.push(item);
    });
  }

  if ('undefined' !== typeof moduleRoutes) {
    for (let moduleName in moduleRoutes) {
      if (moduleRoutes[moduleName].length) {
        moduleRoutes[moduleName].forEach((route, i) => {
          let rk = `${moduleName}-route-${i}`;
          let tmpRoute = Object.assign({}, route);
          routes.push(
            <Route key={rk} {...tmpRoute}>
              <DynamicImport
                module={tmpRoute.module}
                load={() =>
                  import(
                    /* webpackChunkName: "[request]" */ `../modules/${tmpRoute.module}/`
                  )
                }
              >
                {(Component) =>
                  Component === null ? <Loading /> : <Component />
                }
              </DynamicImport>
            </Route>
          );
          if (route.nav && route.nav.label) {
            NavLinks.push(route);
          }
          /*
          let lk = `lkr-${i}`;
          morelinkz.push(<li key={lk}>
            <Link to={tmpRoute.path}>[ {moduleName} ] {tmpRoute.path} {tmpRoute.module}</Link>
          </li>);
          */
        });
      }
    }
  }

  routes.push(<Route key="not-found" component={NotFoundPage} />);

  let footerMessage;

  if (process.env.NODE_ENV === 'development') {
    footerMessage = (
      <div className="notification is-danger">development mode</div>
    );
  }

  let appClass = '';
  if (true === darkMode) {
    appClass += ' theme-dark';
  }

  return (
    <Router>
      <div id="app" className={appClass}>
        <NavBar links={NavLinks} setAuth={setAuth} />
        <div className="main-container container">
          <div className="columns">
            <div className="column is-12 page-content">
              <Switch>{routes}</Switch>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="container">
            <div className="columns">
              <div className="column is-6">{footerMessage}</div>
              <div className="column is-6 has-text-right is-small">
                {_n} v.{_v}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
};

function mapState(state) {
  return {
    darkMode: state.auth.darkMode,
    debugMode: state.auth.debugMode,
    fullWidth: state.auth.fullWidth,
    moduleRoutes: state.auth.moduleRoutes,
    appConfig: state.auth.appConfig,
    req: state.auth.req,
    userAccount: state.auth.account,
  };
}

export default connect(mapState, mapDispatch)(App);
