export const REQ_STATUS_BUSY = 'REQ_STATUS_BUSY';
export const REQ_STATUS_IDLE = 'REQ_STATUS_IDLE';

export const REQ_LAST_STATUS_ERROR = 'REQ_LAST_STATUS_ERROR';
export const REQ_LAST_STATUS_OK = 'REQ_LAST_STATUS_OK';

export const ACTION_SET_AUTH = 'ACTION_SET_AUTH';
export const ACTION_AUTH_REQ_START = 'ACTION_AUTH_REQ_START';
export const ACTION_AUTH_ADD_ROUTES = 'ACTION_AUTH_ADD_ROUTES';
export const ACTION_AUTH_REMOVE_ROUTES = 'ACTION_AUTH_REMOVE_ROUTES';
export const ACTION_AUTH_SET_CONFIG = 'ACTION_AUTH_SET_CONFIG';

export const ACTION_AUTH_TOGGLE_DARKMODE = 'ACTION_AUTH_TOGGLE_DARKMODE';
export const ACTION_AUTH_TOGGLE_DEBUGMODE = 'ACTION_AUTH_TOGGLE_DEBUGMODE';
export const ACTION_AUTH_TOGGLE_FULLWIDTH = 'ACTION_AUTH_TOGGLE_FULLWIDTH';
export const ACTION_SET_BRANDING = 'ACTION_SET_BRANDING';

export const AUTH_CHECK_WHOAMI = 'AUTH_CHECK_WHOAMI';
export const ACTION_AUTH_REMOVE_QR_CODE = 'ACTION_AUTH_REMOVE_QR_CODE';
export const ACTION_SET_ROLES = 'ACTION_SET_ROLES';
export const ACTION_AUTH_ADD_QR_CODE = 'ACTION_AUTH_ADD_QR_CODE';
export const ACTION_SET_TAXONOMY = 'ACTION_SET_TAXONOMY';
export const ACTION_SET_TAXONOMY_ATTACHMENTS =
  'ACTION_SET_TAXONOMY_ATTACHMENTS';

export const SET_BASE_URL = 'SET_BASE_URL';
