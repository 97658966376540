let config;

if (process.env.NODE_ENV === 'development') {
  config = {
    apiurl: 'http://localhost:4242/api',
    baseurl: 'http://localhost:4242/',
    defaultPlaceAvatar: 'avatars/places/default.png',
    defaultFornitureAvatar: 'avatars/fornitures/default.png',
    defaultCartAvatar: 'avatars/carts/default.png',
    defaultUserAvatar: 'avatars/users/default.png',
    defaultMoodAvatar: 'avatars/moods/default.png',
    //defaultBrandingAvatar: 'avatars/branding/default.png',
    defaultBrandingAvatar: 'logo.png',
    cartsUploadFolder: 'uploads/carts',
    ws : {
      url: 'ws://192.168.2.69',
      port: 4242,
      ident: 'MHS_DEV',
      timeout: 5000,
    }
  };
} else {
  config = {
    apiurl: '/api',
    baseurl: '/',
    defaultPlaceAvatar: 'avatars/places/default.png',
    defaultFornitureAvatar: 'avatars/fornitures/default.png',
    defaultBrandingAvatar: 'avatars/branding/default.png',
    defaultCartAvatar: 'avatars/carts/default.png',
    defaultUserAvatar: 'avatars/users/default.png',
    defaultMoodAvatar: 'avatars/moods/default.png',
    cartsUploadFolder: 'uploads/carts',
  };
}

export default config;
