import { createReducer } from '@reduxjs/toolkit';

import { REQ_STATUS_IDLE, REQ_STATUS_BUSY } from '../../utilities/Types';
import {
  setAuth,
  reqStart,
  reqIdle,
  addRoutes,
  removeModuleRoutes,
  setConfig,
  setDarkMode,
  setFullWidth,
  setDebugMode,
  setTaxonomy,
  setTaxonomyAttachments,
  setBranding,
  setRoles,
  addQrCode,
  removeQrCode,
  setBaseURL,
  setWsURL
} from '../appActions';

/*
function isActionWithNumberPayload(action) {
  return typeof action.payload === 'number'
}
{ path: '/invoice/new', exact: true, module: 'invoice' },
{ path: '/invoice/:id/:tab', exact: true, module: 'invoice' },
{ path: '/invoice/:id', exact: true, module: 'invoice' },
{ path: '/invoices', exact: true, module: 'invoice' }
*/

const authReducer = createReducer(
  {
    appConfig: false,
    account: false,
    req: REQ_STATUS_IDLE,
    darkMode: false,
    debugMode: false,
    fullWidth: false,
    taxonomy: {},
    branding: false,
    roles: false,
    qrCodes: [],
    taxonomyAttachments: {},
    moduleRoutes: {
      app: [
        { path: '/', exact: true, module: 'dashboard' },
        { path: '/dashboard', exact: true, module: 'dashboard' },
        {
          path: '/places',
          exact: true,
          module: 'places',
          nav: { label: 'Luoghi' },
        },
        { path: '/place/new', exact: true, module: 'places' },
        { path: '/place/:id', exact: true, module: 'places' },
        {
          path: '/fornitures',
          exact: true,
          module: 'fornitures',
          nav: { label: 'Arredi' },
        },
        { path: '/forniture/new', exact: true, module: 'fornitures' },
        { path: '/forniture/:id', exact: true, module: 'fornitures' },
        {
          path: '/shifts',
          exact: true,
          module: 'shifts',
          nav: { label: 'Trasferimenti' },
        },
        { path: '/shift/:id', exact: true, module: 'shifts' },
        {
          path: '/carts',
          exact: true,
          module: 'carts',
          nav: { label: 'Carrelli' },
        },
        { path: '/cart/new', exact: true, module: 'carts' },
        {
          path: '/admin',
          exact: true,
          module: 'admin',
        },
        { path: '/cart/:id', exact: true, module: 'carts' },
        {
          path: '/moods',
          exact: true,
          module: 'moods',
          nav: { label: 'Moods' },
        },
        { path: '/mood/new', exact: true, module: 'moods' },
        { path: '/mood/:id', exact: true, module: 'moods' },
        {
          path: '/users',
          exact: true,
          module: 'users',
          nav: { label: 'Utenti' },
        },
        { path: '/user/new', exact: true, module: 'users' },
        { path: '/user/:id', exact: true, module: 'users' },
      ],
    },
  },
  (builder) => {
    builder
      .addCase(setAuth, (state, action) => {
        // action is inferred correctly here
        state.account = action.payload;
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      .addCase(reqStart, (state, action) => {
        state.req = REQ_STATUS_BUSY;
      })
      .addCase(reqIdle, (state, action) => {
        state.req = REQ_STATUS_IDLE;
      })
      .addCase(setTaxonomy, (state, action) => {
        state.taxonomy = action.payload;
      })
      .addCase(setBranding, (state, action) => {
        state.branding = action.payload;
      })
      .addCase(setRoles, (state, action) => {
        state.roles = action.payload.roles;
        state.perms = action.payload.perms;
      })
      .addCase(setTaxonomyAttachments, (state, action) => {
        state.taxonomyAttachments[action.payload.id] =
          action.payload.attachments;
      })
      .addCase(setConfig, (state, action) => {
        state.appConfig = action.payload;
      })
      .addCase(removeModuleRoutes, (state, action) => {
        delete state.moduleRoutes[action.payload.module];
      })
      .addCase(setDarkMode, (state, action) => {
        state.darkMode = !state.darkMode;
      })
      .addCase(addQrCode, (state, action) => {
        if (-1 === state.qrCodes.indexOf(action.payload)) {
          state.qrCodes.push(action.payload);
        }
      })
      .addCase(removeQrCode, (state, action) => {
        let tmpCodes = state.qrCodes.map((e) => e);

        if (-1 !== tmpCodes.indexOf(action.payload)) {
          tmpCodes.splice(tmpCodes.indexOf(action.payload), 1);
          state.qrCodes = tmpCodes;
        }
      })
      .addCase(setFullWidth, (state, action) => {
        state.fullWidth = !state.fullWidth;
      })
      .addCase(setBaseURL, (state, action) => {
        state.appConfig.baseurl = action.payload;
      })
      .addCase(setWsURL, (state, action) => {
        state.appConfig.ws = action.payload;
      })
      .addCase(setDebugMode, (state, action) => {
        state.debugMode = !state.debugMode;
      })
      .addCase(addRoutes, (state, action) => {
        if ('undefined' === typeof state.moduleRoutes[action.payload.module]) {
          state.moduleRoutes[action.payload.module] = action.payload.routes;
        }
      });

    // You can apply a "matcher function" to incoming actions
    //.addMatcher(isActionWithNumberPayload, (state, action) => {})
    // and provide a default case if no other handlers matched
    //.addDefaultCase((state, action) => {})
  }
);

export default authReducer;
